<template>
  <v-card flat>
    <v-card-title class="text-body-2 px-0 pt-0 pb-1 d-flex align-end">
      Periodo: {{ calendario.inicio | dateFormat("dd/MM/yyyy") }} à
      {{ calendario.fim | dateFormat("dd/MM/yyyy") }}

      <v-spacer></v-spacer>
      <!-- Aprovar Calendario -->
      <v-btn
        color="primary"
        class="white--text mr-2 mb-2 ml-2"
        @click="gerarpdf"
        :disabled="!postagensCalendarios.length"
      >
        PDF
      </v-btn>
      <v-divider vertical class="mx-3"></v-divider>
      <v-btn
        :color="calendario.status === -1 ? 'success' : 'error'"
        class="white--text mr-2 mb-2 ml-2"
        :disabled="calendario.status >= 3 || !postagensCalendarios.length"
        @click="ativarDesativarCalendario"
      >
        {{
          calendario.status === -1
            ? "ativar"
            : calendario.status === 2
            ? "refazer"
            : "cancelar"
        }}
      </v-btn>
      <v-btn
        color="success"
        class="white--text mr-2 mb-2 ml-2"
        :disabled="calendario.status !== 2 || !postagensCalendarios.length"
        @click="aprovarCalendario"
      >
        aprovar
      </v-btn>

      <v-btn
        color="warning"
        class="white--text mr-2 mb-2 ml-2"
        :disabled="calendario.status !== 2 || !postagensCalendarios.length"
        @click="mudancaStatusAprovado"
      >
        Finalizar
      </v-btn>
      <v-divider vertical class="mx-3"></v-divider>
      <v-btn
        v-if="calendario.status"
        @click="addPostagemCalendario"
        :disabled="calendario.status > 3"
        color="secondary"
        class="white--text mb-2 ml-2"
      >
        adicionar
      </v-btn>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="postagensCalendarios"
        :items-per-page="10"
        class="data-tables"
        :class="{ 'data-tables__row-click': calendario.status > 3 }"
        @click:row="editPostagemCalendario"
      >
        <!-- Data -->
        <template v-slot:item.data="{ item }">
          {{ item.data | dateFormat("dd/MM/yyyy") }}
        </template>

        <!-- Tema -->
        <template v-slot:item.tema="{ item }">
          <div class="d-flex justify-start align-center">
            <v-sheet
              label
              :color="item.tema.cor"
              class="rounded-circle mr-2"
              height="20px"
              width="20px"
            >
            </v-sheet>

            {{ item.tema.descricao }}
          </div>
        </template>

        <!-- Formato  -->
        <template v-slot:item.formato="{ item }">
          {{ item.formato.descricao }}
        </template>

        <!-- Redes Sociais -->
        <template v-slot:item.redes_sociais="{ item }">
          <div class="d-flex">
            <v-tooltip
              top
              v-for="(rede_social, index) in item.redes_sociais"
              :key="index"
              max-width="220px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-card
                  width="25px"
                  height="25px"
                  v-bind="attrs"
                  v-on="on"
                  flat
                  class="pa-1 mr-1"
                >
                  <v-img :src="rede_social.icone" contain> </v-img>
                </v-card>
              </template>
              <div>
                <span
                  v-for="(local, index_local) in rede_social.locais"
                  :key="index_local"
                >
                  {{ local.descricao
                  }}{{
                    index_local === rede_social.locais.length - 1 ? "" : ", "
                  }}
                </span>
              </div>
            </v-tooltip>
          </div>
        </template>

        <!-- deletar -->
        <template v-slot:item.deletar="{ item }">
          <div class="d-flex">
            <v-btn
              v-if="calendario.status === 1"
              small
              icon
              @click.stop="deleteItem(item.id)"
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card-text>

    <AddEditPostagemMidiaSociais
      v-if="dialogAddPostagem"
      :dialogAddPostagem.sync="dialogAddPostagem"
      :data_inicio="calendario.inicio"
      :data_fim="calendario.fim"
      :postagem_id="postagem_id"
      :edit="edit"
      :calendario="calendario"
      @fetch-postagem="fetchPostagens"
    />
    <PdfDialogComp
      v-if="pdfDialog && edit == false"
      :pdfDialog.sync="pdfDialog"
      :pdfData="pdfData"
      :title="calendario.cliente"
    />
  </v-card>
</template>

<script>
import { deleteCalendarioPostagem } from "@/api/gestor/midias_sociais_calendario_postagem.js";
import { putMidiaSocialCalendario } from "@/api/gestor/midias_sociais_calendario.js";
import { putAprovacaoMidiaSocialCalendario } from "@/api/geral/midias_sociais_calendario.js";

const pdfGenerator = () =>
  import(/* webpackChunkName: "relatorio" */ "../pdf/calendarioPdf.js");

export default {
  name: "ListaPostagemCalendarioMidias",

  props: {
    calendario: {
      type: Object,
      required: true,
    },
    postagensCalendarios: {
      type: Array,
      required: true,
    },
  },

  components: {
    AddEditPostagemMidiaSociais: () =>
      import("./AddEditPostagemMidiaSociais.vue"),
    PdfDialogComp: () =>
      import("../../../../../../../components/PdfDialogComp.vue"),
  },

  data() {
    return {
      loading: true,
      postagem_id: null,
      dialogAddPostagem: false,
      edit: false,
      pdfDialog: false,
      loadingAprovadoGestor: false,
      iconAprovado: "mdi-check-decagram",
      iconCancelado: "",
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "DATA",
          width: "130px",
          value: "data",
        },
        {
          text: "HORA",
          width: "130px",
          value: "hora",
        },
        {
          text: "ASSUNTO",
          sortable: false,
          value: "assunto",
        },
        {
          text: "TEMA",
          width: "170px",
          sortable: false,
          value: "tema",
        },
        {
          text: "FORMATO",
          width: "160px",
          sortable: false,
          value: "formato",
        },

        {
          text: "REDES SOCIAIS",
          width: "200px",
          sortable: false,
          value: "redes_sociais",
        },

        {
          text: "",
          width: "70px",
          sortable: false,
          value: "deletar",
        },
      ];
    },

    calendario_id() {
      return this.$route.params.calendarioId;
    },
  },

  methods: {
    mudancaStatusAprovado() {
      this.loadingAprovadoGestor = true;
      let calendario = {};

      if (this.calendario.status === 2) {
        calendario.status = 4;

        putAprovacaoMidiaSocialCalendario(this.calendario_id, calendario)
          .then((response) => {
            if (response.status === 201) {
              this.$router.push({
                path: `/gestor/formulario-trabalho/midias-sociais/calendario-midias-sociais/`,
              });
              this.$toast.success("Calendário aprovado, atividades criadas!");
              this.loadingAprovadoGestor = false;
            }
          })
          .finally(() => {
            this.loadingAprovadoGestor = false;
          });
      }
    },
    addPostagemCalendario() {
      this.postagem_id = null;
      this.dialogAddPostagem = true;
      this.edit = false;
    },

    editPostagemCalendario(item) {
      if (this.calendario.status <= 3) {
        this.postagem_id = item.id;
        this.dialogAddPostagem = true;
        this.edit = true;
      }
    },

    aprovarCalendario() {
      let calendario = {};

      if (this.calendario.status === 2) {
        calendario.status = 3;
        this.$toast.success("Calendário aprovado, aguarde o cliente aprovar!");
      }
      putMidiaSocialCalendario(this.calendario_id, calendario)
        .then((response) => {
          if (response.status === 201) {
            this.$router.push({
              path: `/gestor/formulario-trabalho/midias-sociais/calendario-midias-sociais/`,
            });

            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    ativarDesativarCalendario() {
      let calendario = {};

      if (this.calendario.status === 1) {
        calendario.status = -1;
        this.$toast.success("Calendário cancelado!");
      } else if (this.calendario.status === -1) {
        calendario.status = 1;
        this.$toast.success("Calendário ativo!");
      } else if (this.calendario.status === 2) {
        calendario.status = 1;
        this.$toast.success("Calendário enviado para refazer!");
      }
      putMidiaSocialCalendario(this.calendario_id, calendario)
        .then((response) => {
          if (response.status === 201) {
            this.$router.push({
              path: `/gestor/formulario-trabalho/midias-sociais/calendario-midias-sociais/`,
            });

            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    fetchPostagens() {
      this.$emit("fetch-postagem");
    },

    deleteItem(id) {
      if (this.calendario.status == 1) {
        deleteCalendarioPostagem(id).then((response) => {
          if (response.status === 201) {
            this.$toast.success("Excluido com sucesso!");
            this.$emit("fetch-postagem");
          }
        });
      }
    },

    async gerarpdf() {
      try {
        this.loading = true;
        this.$Progress.start();

        await pdfGenerator()
          .then((module) => {
            let generate = module.generatePdf;
            generate(this.calendario, this.postagensCalendarios).then(
              (response) => {
                this.pdfData = response;
                this.pdfDialog = true;
              }
            );
          })
          .finally(() => {
            this.loading = false;
            this.$Progress.finish();
          });
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$Progress.fail();
      }
    },
  },

  mounted() {
    // this.pdfContentTeste();
  },
};
</script>

<style lang="sass" scoped></style>
